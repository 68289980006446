<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="https://adabislami.org/" target="_blank" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/sata-go.png" alt="" height="80">
          </span>
          <span class="logo-lg">
            <img src="assets/sata-go-light.png" alt="" height="80">
          </span>
        </a>

        <a href="https://adabislami.org/" target="_blank" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/sata-go.png" alt="" height="80">
          </span>
          <span class="logo-lg">
            <img src="assets/sata-go-light.png" alt="" height="80">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->


     </div>

    <div class="d-flex">




      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>







      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/user.png" alt="{{userinfo.user.username}}">
          <span class="d-none d-xl-inline-block ms-1">{{userinfo.user.username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
<!--          <a class="dropdown-item" routerLink="profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>-->
<!--            {{GetWordText('profile')}}</a>-->
<!--           <a class="dropdown-item"  (click)="logout()" routerLink="javascript: void(0);"><i-->
<!--              class="bx bx-lock-open font-size-16 align-middle me-1"></i>-->
<!--            {{GetWordText('change_password')}}</a>-->
<!--          <div class="dropdown-divider"></div>-->
          <a class="dropdown-item text-danger" routerLink="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{GetWordText('logout')}}</a>
        </div>
      </div>



    </div>
  </div>
</header>
