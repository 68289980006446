import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Language {

  lang_words: Array<any>;

  constructor() {
    this.lang_words = [
      {text_key: "_", text_ar: " ", text_en: " "}, {
        text_key: "home",
        text_ar: "الرئيسية",
        text_en: "Home"
      }, {
        text_key: "login_content",
        text_ar: "سجل دخول الى لوحة التحكم باستخدام البريد الالكتروني وكلمة المرور ",
        text_en: " Log in to the control panel using your email and password "
      },
      {text_key: "login", text_ar: " دخول ", text_en: " Login "},
      {text_key: "username", text_ar: " اسم المستخدم ", text_en: " Username "},
      {text_key: "username_required", text_ar: " فضلا أدخل اسم المستخدم  ", text_en: " Enter user name  "},
      {text_key: "password",text_ar: " كلمة المرور ",text_en: " Password "},
      {text_key: "email", text_ar: "البريد الالكتروني", text_en: " Email "},
      {text_key: "remember_me", text_ar: "تذكرني", text_en: "Remember me"},
      {text_key: "change_lang", text_ar: "تغيير اللغة", text_en: "Change Language"},
      {text_key: "welcome", text_ar: "مرحبا", text_en: "Welcome"},
      {text_key: "welcome_text", text_ar: "مرحبا بك نتمني لك يوم عمل جيد", text_en: "Welcome, we wish you a good working day"},
      {text_key: "copy_rights", text_ar: "جميع الحقوق محفوظة", text_en: "All rights reserved"},
      {text_key: "company_name", text_ar: "رابطة الادب الاسلامي العالمية", text_en: "  Developments"},
      {text_key: "login_information_not_correct", text_ar: "معلومات الدخول غير صحيحة", text_en: "Incorrect login information"},
      {text_key: "dashboard", text_ar: "لوحة التحكم", text_en: "Dashboard"},
      {text_key: "sliders", text_ar: "سلايدر", text_en: "Sliders"},
      {text_key: "offers", text_ar: "العروض", text_en: "Offers"},
      {text_key: "news", text_ar: "الأخبار", text_en: "News"},
      {text_key: "events", text_ar: "الأحداث", text_en: "Events"},
      {text_key: "projects", text_ar: "المشروعات", text_en: "Projects"},
      {text_key: "project_units", text_ar: "وحدات المشروعات ", text_en: "Project Unites"},
      {text_key: "service_providers", text_ar: "مقدمي الخدمات", text_en: "Service Providers"},
      {text_key: "services", text_ar: "الخدمات", text_en: "Services"},
      {text_key: "services_request", text_ar: "طلبات الخدمات", text_en: "Services Requests"},
      {text_key: "installments", text_ar: "الاقساط", text_en: "Installments"},
      {text_key: "transactions", text_ar: "العمليات المالية", text_en: "Transactions"},
      {text_key: "finance", text_ar: "الحسابات", text_en: "Finance"},
      {text_key: "users", text_ar: "المستخدمين", text_en: "Users"},
      {text_key: "clients", text_ar: "العملاء", text_en: "Clients"},
      {text_key: "notifications", text_ar: "الأشعارات", text_en: "Notifications"},
      {text_key: "owners_notifications", text_ar: "إشعارات الملاك", text_en: "Owners Notifications"},
      {text_key: "project_updates", text_ar: "اشعارات المشروعات", text_en: "Projects Updates"},
      {text_key: "admins_log", text_ar: "سجلات المستخدمين", text_en: "Admins Log"},
      {text_key: "adminslog", text_ar: "سجلات المستخدمين", text_en: "Admins Log"},
      {text_key: "cities", text_ar: "المدن", text_en: "Cities"},
      {text_key: "unit_types", text_ar: "أنواع الوحدات", text_en: "Unit Types"},
      {text_key: "projects_status", text_ar: "حالات المشروعات", text_en: "Projects Status"},
      {text_key: "settings", text_ar: "الاعدادات", text_en: "Settings"},
      {text_key: "payment_methods", text_ar: "وسائل الدفع", text_en: "Payments Methods"},
      {text_key: "vehicles_brands", text_ar: "أنواع السيارات", text_en: "Vehicles Brands"},
      {text_key: "configuration", text_ar: "الأعدادات", text_en: "Configuration"},
      {text_key: "all", text_ar: "الجميع", text_en: "All"},
      {text_key: "deleted", text_ar: "مسودة", text_en: "Draft"},
      {text_key: "upcoming", text_ar: "بعد", text_en: "Upcoming"},
      {text_key: "exportImport", text_ar: "تصدير وإستيراد", text_en: "Export Import"},
      {text_key: "name", text_ar: "الاسم", text_en: "Name"},
      {text_key: "email", text_ar: "البريد الألكتروني", text_en: "Email"},
      {text_key: "mobile", text_ar: "رقم الموبايل", text_en: "Mobile"},
      {text_key: "date", text_ar: "التاريخ", text_en: "Date"},
      {text_key: "start_date", text_ar: "تاريخ البدء", text_en: "Start Date"},
      {text_key: "end_date", text_ar: "تاريخ النهاية", text_en: "End Date"},
      {text_key: "title_ar", text_ar: "العنوان AR", text_en: "Title AR"},
      {text_key: "title_en", text_ar: "العنوان  EN", text_en: "Title EN"},
      {text_key: "content_ar", text_ar: "المحتوي AR", text_en: "Content AR"},
      {text_key: "content_en", text_ar: "المحتوي  EN", text_en: "Content EN"},
      {text_key: "description_ar", text_ar: "الوصف  AR", text_en: "Description AR"},
      {text_key: "description_en", text_ar: "الوصف EN", text_en: "Description EN"},
      {text_key: "delete", text_ar: "حذف", text_en: "Delete"},
      {text_key: "add_new", text_ar: "إضافة جديد", text_en: "Add New"},
      {text_key: "search", text_ar: "بحث", text_en: "Search"},
      {text_key: "save", text_ar: "حفظ", text_en: "Save"},
      {text_key: "save_update", text_ar: "حفظ التغييرات", text_en: "Save Updates"},
      {text_key: "admins", text_ar: "الادارة والعاملين", text_en: "Admins&Employers"},
      {text_key: "admins_levels", text_ar: "اقسام الادارة", text_en: "Admins Levels"},
      {text_key: "admins_access", text_ar: "صلاحيات الوصول", text_en: "Admins Access "},
      {text_key: "unit_resale_request", text_ar: "طلبات اعادة بيع الوحدات", text_en: "Units Resale Requests"},
      {text_key: "name_ar", text_ar: "الاسم AR ", text_en: "Name AR"},
      {text_key: "name_en", text_ar: "الاسم EN", text_en: "Name EN"},
      {text_key: "actions", text_ar: " الاجراءات", text_en: "Actions"},
      {text_key: "lat_lng", text_ar: "الاحداثيات", text_en: "Map Lat,Lng"},
      {text_key: "show", text_ar: "عرض", text_en: "Show"},
      {text_key: "entries", text_ar: "مدخل", text_en: "Entries"},
      {text_key: "Edit", text_ar: "تعديل", text_en: "Edit"},
      {text_key: "update", text_ar: "تعديل", text_en: "Update"},
      {text_key: "icon", text_ar: "صورة رمزية ", text_en: "Icon"},
      {text_key: "deleteConfirm", text_ar: " هل انت متأكد من  الحذف ؟", text_en: "Are you sure delete this item ? "},
      {text_key: "yesDeleteIt", text_ar: "نعم إحذف ذلك ", text_en: "Yes delete it "},
      {text_key: "to", text_en: "To", text_ar: "الي"},
      {text_key: "of", text_en: "Of", text_ar: "من"},
      {text_key: "Showing", text_en: "Showing", text_ar: "معروض"},
      {text_key: "restore", text_ar: "إستعادة", text_en: "Restore"},
      {text_key: "restoreConfirm", text_ar: "هل انت متأكد من إستعادة هذا ؟", text_en: "Are you sure restore this item ?"},
      {text_key: "yes_restore_it", text_ar: "نعم", text_en: "Yes"},
      {text_key: "success", text_ar: "عملية ناجحة", text_en: "Success"},
      {text_key: "add_successfully", text_ar: "عملية إضافة ناجحة", text_en: "Added Successfully"},
      {text_key: "deleted_successfully", text_ar: "تم الحذف بنجاح", text_en: "Deleted Successfully"},
      {text_key: "restored_successfully", text_ar: "تم الاستعادة بنجاح", text_en: "Restored Successfully"},
      {text_key: "alert", text_ar: "إنتبة", text_en: "Alert"},
      {text_key: "photo", text_ar: "صورة", text_en: "Photo"},
      {text_key: "project_id", text_ar: "المشروع", text_en: "Project"},
      {text_key: "unit_id", text_ar: "الوحدة", text_en: "Unit"},
      {text_key: "select", text_ar: "اختر", text_en: "Select"},
      {text_key: "please_enter", text_ar: "فضلا ادخل", text_en: "Please Enter "},
      {text_key: "photos", text_ar: "الصور", text_en: "Photos"},
      {text_key: "address_ar", text_ar: "العنوان AR", text_en: "Address AR"},
      {text_key: "address_en", text_ar: "العنوان EN", text_en: "Address EN"},
      {text_key: "project_status", text_ar: "حالة المشروع", text_en: "Project Status"},
      {text_key: "city_id", text_ar: "المدينة", text_en: "City"},
      {text_key: "export", text_ar: "تصدير", text_en: "Export"},
      {text_key: "error", text_ar: "خطأ", text_en: "Error"},
      {text_key: "please_enter_required_inputs", text_ar: "فضلا أدخل جميع الحقول الألزامية", text_en: "Please enter required inputs"},
      {text_key: "projects_gallery", text_ar: "مركز الميديا", text_en: "Projects gallery"},
      {text_key: "gallery_file", text_ar: "الملف", text_en: "File"},
      {text_key: "file_type", text_ar: "نوع الملف", text_en: "File Type"},
      {text_key: "projects_timeline", text_ar: "الجدول الزمني ", text_en: "Projects Timeline"},
      {text_key: "current_status", text_ar: "الحالة", text_en: "Current Status"},
      {text_key: "blocked", text_ar: "حظر", text_en: "Blocked"},
      {text_key: "user_id", text_ar: "المستخدم", text_en: "User"},
      {text_key: "user_level", text_ar: "مستوي الوصول", text_en: "User Level"},
      {text_key: "unit_price", text_ar: "سعر الوحدة", text_en: "Unit Price"},
      {text_key: "unit_price_table", text_ar: "جدول أسعار الوحدات", text_en: "Unit price"},
      {text_key: "down_payment", text_ar: "دفعة تعاقد", text_en: "Down payment"},
      {text_key: "monthly_installment", text_ar: "القسط الشهري", text_en: "Monthly installment"},
      {text_key: "quarter_installment", text_ar: "القسط الربع سنوي", text_en: "Quarter installment"},
      {text_key: "yearly_installment", text_ar: "القسط السنوي", text_en: "Yearly installment"},
      {text_key: "service_installment", text_ar: "رسوم الخدمات  ", text_en: "Service installment"},
      {text_key: "price_valid_to", text_ar: "هذا السعر صالح حتي ", text_en: "Price Valid to date "},
      {text_key: "waiting", text_ar: "بالانتظار", text_en: "Waiting"},
      {text_key: "confirmed", text_ar: "مؤكد", text_en: "Confirmed"},
      {text_key: "completed", text_ar: "مكتمل", text_en: "Completed"},
      {text_key: "canceled", text_ar: "ملغي", text_en: "Canceled"},
      {text_key: "overdo", text_ar: "مر عليه", text_en: "Overdo"},
      {text_key: "plus", text_ar: "إضافة", text_en: "Plus"},
      {text_key: "minus", text_ar: "تنزيل", text_en: "Minus"},
      {text_key: "today", text_ar: "اليوم", text_en: "Today"},
      {text_key: "send", text_ar: "ارسال", text_en: "Send"},
      {text_key: "history", text_ar: "سجل الارسال", text_en: "History"},
      {text_key: "active", text_ar: "تفعيل", text_en: "Active"},
      {text_key: "block", text_ar: "حظر", text_en: "Block"},
      {text_key: "yes", text_ar: "نعم", text_en: "Yes"},
      {text_key: "no", text_ar: "لا", text_en: "No"},
      {text_key: "user_lang", text_ar: "لغة الاستخدام", text_en: "User Lang"},
      {text_key: "parent_account", text_ar: "حساب رئيسي", text_en: "Parent Account"},
      {text_key: "dark_mode", text_ar: "ثمة التطبيق", text_en: "App Theme"},
      {text_key: "darkmode", text_ar: "ثمة التطبيق", text_en: "App Theme"},
      {text_key: "installment_table", text_ar: "أقساط العملاء", text_en: "Clients Installments"},
      {text_key: "client_transactions", text_ar: "معاملات العملاء", text_en: "Clients Transactions"},
      {text_key: "client_id", text_ar: "العميل", text_en: "Client"},
      {text_key: "payment_method", text_ar: "وسيلة الدفع", text_en: "Payment method"},
      {text_key: "trasaction_amount", text_ar: "مبلغ الحركة", text_en: "Transaction Amount"},
      {text_key: "trasaction_type", text_ar: "نوع الحركة", text_en: "Transaction Type"},
      {text_key: "installment_alert", text_ar: "تنبيه الاقساط", text_en: "Installment Alert"},
      {text_key: "installment_alert_days", text_ar: "تنبيه الاقساط قبل", text_en: "Installment Alert days"},
      {text_key: "installment_alert_repeated", text_ar: "تكرار تنبية الاقساط", text_en: "Installment Alert Repeated"},
      {text_key: "project_update_notifications", text_ar: "اشعار بتحديث حالة المشروعات", text_en: "Project updates notifications"},
      {text_key: "user_photo", text_ar: "صورة المستخدم", text_en: "User Photo"},
      {text_key: "admins_level", text_ar: "مستوي الوصول", text_en: "Admin level"},
      {text_key: "path", text_ar: "المسار", text_en: "Path"},
      {text_key: "path_log", text_ar: "المسار", text_en: "Path"},
      {text_key: "edit", text_ar: "التعديل", text_en: "Edit"},
      {text_key: "add", text_ar: "الأضافة", text_en: "Add"},
      {text_key: "table_name", text_ar: "اسم الجدول", text_en: "Table Name"},
      {text_key: "path_action", text_ar: "الأجراء", text_en: "User Action"},
      {text_key: "item_id", text_ar: "المعرف", text_en: "Item ID"},
      {text_key: "log_date_time", text_ar: "تاريخ ووقت السجل", text_en: "Log date and time "},
      {text_key: "log_ip_address", text_ar: "IP", text_en: "IP"},
      {text_key: "star_log", text_ar: "تتميز السجل", text_en: "Mark Log"},
      {text_key: "before_action", text_ar: "قبل الاجراء", text_en: "Before Action"},
      {text_key: "after_action", text_ar: "بعد الاجراء", text_en: "After Action"},
      {text_key: "createdAt", text_ar: "تاريخ الأنشاء", text_en: "Created At"},
      {text_key: "updatedAt", text_ar: "تاريخ التحديث", text_en: "Updated At"},
      {text_key: "messages", text_ar: "الرسائل", text_en: "Messages"},
      {text_key: "request_messages", text_ar: "طلبات التواصل", text_en: "Request Messages"},
      {text_key: "client_units", text_ar: "وحدات العملاء", text_en: "Clients Units "},
      {text_key: "price", text_ar: "Price", text_en: "Price"},
      {text_key: "details", text_ar: "التفاصيل", text_en: "Details"},
      {text_key: "request_status", text_ar: "حالة الطلب", text_en: "Request status"},
      {text_key: "attachments", text_ar: "المرفقات", text_en: "Attachments"},
      {text_key: "installment_price", text_ar: "المبلغ", text_en: "Amount"},
      {text_key: "do_date", text_ar: "تاريخ الاستحقاق", text_en: "Do Date"},
      {text_key: "payment_status", text_ar: "حالة الدفع", text_en: "payment status"},
      {text_key: "days", text_ar: "يوم", text_en: "days"},
      {text_key: "egp", text_ar: "ج.م", text_en: "Egp"},
      {text_key: "paid", text_ar: "مدفوع", text_en: "Paid"},
      {text_key: "payment_confirm", text_ar: "تأكيد الدفع", text_en: "Payment confirm"},
      {text_key: "payment_confirm_msg", text_ar: "هل انت متأكد من العملية ؟", text_en: "Are you sure pay this installment?"},
      {text_key: "payment_unsuccessfully", text_ar: "عملية دفع غير ناجحة", text_en: "Payment Unsuccessfully"},
      {text_key: "bulk_insert", text_ar: "ادخال جماعي", text_en: "Bulk Insert"},
      {text_key: "import", text_ar: "إستيراد", text_en: "Import"},
      {text_key: "download_template", text_ar: "تحميل النموذج", text_en: "Download Template"},
      {text_key:"exportImport",text_ar: "إستيراد وتصدير البيانات", text_en: "Export Import Data "},
      {text_key:"no_data_to_export",text_ar: "لا يوجد أي بيانات للتصدير", text_en: "No have any data to export "},
      {text_key:"import_note_2",text_ar: "الحقل الذي يتحتوي على كلمة empty يجب تركة فارغاً", text_en: "The field containing the word empty must be left blank"},
      {text_key:"export_importing_log",text_ar: "تنزيل تقرير الاستيراد", text_en: "Download Importing Log"},
      {
        text_key: "import",
        text_ar: "إستيراد البيانات",
        text_en: "Import Data "
      }, {text_key: "export", text_ar: "تصدير البيانات ", text_en: " Export Data "}, {
        text_key: "import_note",
        text_ar: " قم بتحميل القالب ثم اضاف البيانات وقم باستيرادها دفعة واحدة ",
        text_en: " Download the template, add the data, and import it in one go "
      }, {
        text_key: "import_note_file",
        text_ar: " يجب عليك الالتزام بتنسيق القالب وعدم تغيير العناوين او اسم الشيت فقط قم بتغيير البيانات ",
        text_en: " You must adhere to the format of the template and not change the addresses or the name of the sheet, just change the data "
      }, {
        text_key: "select_file_and_click_upload",
        text_ar: " فضلا اختر ملف الاكسل ثم اضغط على زر استيراد ",
        text_en: " choose excel file and click import Data button "
      }, {text_key: "uploadfile", text_ar: " استيراد من ", text_en: " Import From "}, {
        text_key: "downloadFile",
        text_ar: " تحميل القالب ",
        text_en: " Download Template "
      }, {text_key: "download", text_ar: " تحميل ", text_en: " Download "}, {
        text_key: "export_note",
        text_ar: "تصدير جميع بيانات الجدول المخزنة بقاعدة البيانات ",
        text_en: " Export all table data stored in the database "
      }, {
        text_key: "export_data_to",
        text_ar: " تصدير البيانات الى ",
        text_en: " export data to "
      }, {
        text_key: "click_and_waiting_server_working",
        text_ar: " اضغط على تصدير البيانات وانتظر قليلا حتي يجهز الخادم البيانات ",
        text_en: " Click on Export Data and wait a while for the server to prepare the data "
      },
      {text_key: "gates", text_ar: "البوابات", text_en: "Gates"},
      {text_key: "gates_emp", text_ar: "عاملين الوبابات", text_en: "Gates Employers"},
      {text_key: "emp_code", text_ar: "كود العامل", text_en: "Employer Code"},
      {text_key: "gate", text_ar: "البوابة", text_en: "Gate"},
      {text_key: "payment_requests", text_ar: "اخطارات السداد", text_en: "Payment Requests"},
      {text_key: "installment_id", text_ar: "القسط", text_en: "installment"},
      {text_key: "amount", text_ar: "المبلغ", text_en: "Amount"},
      {text_key: "method_id", text_ar: "وسيلة الدفع", text_en: "Payment Method"},
      {text_key: "status", text_ar: "الحالة", text_en: "Status"},
      {text_key: "user_confirm", text_ar: "المسئول", text_en: "User confirm"},
      {text_key: "project_code", text_ar: "كود المشروع", text_en: "Project Code"},
      {text_key: "unit_code", text_ar: "كود الوحدة", text_en: "Unit Code"},
      {text_key: "unit_no", text_ar: "رقم الوحدة", text_en: "Unit No"},
      {text_key: "pay_years", text_ar: "سنوات السداد", text_en: "Payment years"},
      {text_key: "message", text_ar: "الرسالة", text_en: "Message"},
      {text_key: "title", text_ar: "العنوان", text_en: "Title"},
      {text_key: "read_status", text_ar: "قرأة", text_en: "Read"},
      {text_key: "messages_request", text_ar: "طلبات المراسلة", text_en: "Message Request"},
      {text_key: "meeting_booking", text_ar: "حجز المواعيد", text_en: "Meeting Booking"},
      {text_key: "booking_date", text_ar: "تاريخ الحجز", text_en: "Booking date"},
      {text_key: "unit_price_id", text_ar: "نمط التسعير", text_en: "Price schema"},
      {text_key: "soldout", text_ar: "اكتمل البيع", text_en: "Sold out"},
      {text_key: "knowledge", text_ar: "المعرفة", text_en: "knowledge"},
      {text_key: "knowledge_departments", text_ar: "الاقسام", text_en: "Departments"},
      {text_key: "department_id", text_ar: "القسم", text_en: "Department"},
      {text_key: "db_backup", text_ar: "نسخ احتياطي", text_en: "DB backup"},
      {text_key: "app", text_ar: "التطبيق", text_en: "App"},
      {text_key: "theme", text_ar: "ثمة التطبيق", text_en: "Theme"},
      {text_key: "data_set", text_ar: "تهيئة البيانات", text_en: "Data set"},
      {text_key: "client_code", text_ar: "كود العميل", text_en: "Client Code"},
      {text_key: "mobil2", text_ar: "موبايل 2", text_en: "mobile 2"},
      {text_key: "mobil3", text_ar: "موبايل 3", text_en: "mobile 3"},
      {text_key: "mobil4", text_ar: "موبايل 4", text_en: "mobile 4"},
      {text_key: "partially", text_ar: "مسدد جزئي", text_en: "Partially"},
      {text_key: "o_check", text_ar: "Deposited check", text_en: "شيك مودع"},
      {text_key: "c_check", text_ar: "Collected check", text_en: "شيك محصل"},
      {text_key: "youtube_gallery", text_ar: "يوتيوب ", text_en: "Youtube gallery"},
      {text_key: "icons_link", text_en: "Icons Urls", text_ar: "روابط"},
      {text_key: "text_ar", text_ar: "نص ar", text_en: "Text Ar"},
      {text_key: "text_en", text_ar: "نص en", text_en: "Text En"},
      {text_key: "event", text_ar: "حدث", text_en: "Event"},
      {text_key: "units", text_ar: "الوحدات", text_en: "Unites "},
      {text_key: "installment_paid", text_ar: "الاقساط المسددة", text_en: "Installment paid"},
      {text_key: "installment_waiting", text_ar: "الاقساط الغير مسددة", text_en: "Installment waiting"},
      {text_key: "installment_total", text_ar: "اجمال المبيعات", text_en: "Installment total"},
      {text_key: "erp_import", text_ar: "استبراد ERP", text_en: "ERP Import"},
      {text_key: "erpImport", text_ar: "استبراد ERP", text_en: "ERP Import"},
      {text_key: "youtube_url", text_ar: "رابط اليوتيوب", text_en: "Youtube URL"},
      {text_key: "erp_import_note", text_ar: "يجب مراعاة ان يكون اول صف فى الاكسل هو نفس الصورة التالية", text_en: "يجب مراعاة ان يكون اول صف فى الاكسل هو نفس الصورة التالية"},
      {text_key: "erp_import_note_2", text_ar: "", text_en: ""},
      {text_key: "downpayment", text_ar: "دفعة مقدمة", text_en: "Down Payment"},
      {text_key: "unit_space", text_ar: "مساحة الوحدة", text_en: "Unit Space"},
      {text_key: "unit_m_price", text_ar: "سعر متر الوحدة", text_en: "Unit Meter Price"},
      {text_key: "garden_space", text_ar: "مساحة الحديقة", text_en: "Garden Space"},
      {text_key: "garden_m_price", text_ar: "سعر متر الحديقة", text_en: "Garden Meter Price"},
      {text_key: "discount", text_ar: "الخصم", text_en: "Discount"},
      {text_key: "payment_years", text_ar: "سنوات السداد", text_en: "Payment years "},
      {text_key: "first_installment", text_ar: "موعد اول قسط", text_en: "First installment date"},
      {text_key: "payment_plan", text_ar: "خطة مدفوعات", text_en: "Payment plan"},
      {text_key: "grag_price", text_ar: "سعر الجراج", text_en: "Garage Price"},
      {text_key: "view_plan", text_ar: "عرض الخطة", text_en: "View Plan"},
      {text_key: "download_pdf", text_ar: "تحميل PDF", text_en: "Download PDF"},
      {text_key: "count_books", text_ar: "الكتب", text_en: "Books"},
      {text_key: "count_articles", text_ar: "المقالات", text_en: "Articles"},
      {text_key: "count_news", text_ar: "الاخبار", text_en: "News"},
      {text_key: "count_users", text_ar: "الاعضاء", text_en: "Users"},
      {text_key: "new_messages", text_ar: "رسائل جديدة", text_en: "New Messages"},
      {text_key: "new_books_request", text_ar: "طلبات اضافة كتاب", text_en: "New Books Request"},
      {text_key: "editions", text_ar: "الاصدارات", text_en: "Editions"},
      {text_key: "live", text_ar: "منشور", text_en: "Live"},
      {text_key: "draft", text_ar: "مسودة", text_en: "Draft"},
      {text_key: "mag", text_ar: "المجلة", text_en: "Magazine"},
      {text_key: "emag", text_ar: "المجلة الألكترونية", text_en: "E Magazine"},
      {text_key: "site", text_ar: "اخبار الموقع", text_en: "Site"},
      {text_key: "author_id", text_ar: "الكاتب", text_en: "Author"},
      {text_key: "views", text_ar: "المشاهدات", text_en: "Views"},
      {text_key: "category_id", text_ar: "القسم", text_en: "Category"},
      {text_key: "edition_id", text_ar: "الاصدار", text_en: "Edition"},
      {text_key: "type", text_ar: "النوع", text_en: "Type"},
      {text_key: "attachment", text_ar: "الصورة", text_en: "Attachment"},
      {text_key: "intro", text_ar: "مقدمة", text_en: "Intro"},
      {text_key: "slug", text_ar: "المعرف", text_en: "Slug"},
      {text_key: "body", text_ar: "التفاصيل", text_en: "Details"},
      {text_key: "electronic", text_ar: "إلكترونية", text_en: "Electronic"},
      {text_key: "printed", text_ar: "مطبوعة", text_en: "Printed"},
      {text_key: "cover", text_ar: "االغلاف", text_en: "Cover"},
      {text_key: "link_cover", text_ar: "الرابط", text_en: "Link Cover"},
      {text_key: "pdf", text_ar: "ملف PDF", text_en: "PDF File"},
      {text_key: "pdf_editions", text_ar: "الأعداد PDF", text_en: "PDF Editions"},
      {text_key: "characters", text_ar: "شخصيات الأدب الأسلامي", text_en: "Islamic Characters"},
      {text_key: "partnars", text_ar: "شركاء النجاح", text_en: "Partners"},
      {text_key: "logo", text_ar: "الشعار", text_en: "Logo"},
      {text_key: "url", text_ar: "الرابط", text_en: "URL"},
      {text_key: "facebook", text_ar: "فيس بوك", text_en: "Facebook"},
      {text_key: "twitter", text_ar: "تويتر / اكس", text_en: "Twitter/X"},
      {text_key: "linkedIn", text_ar: "لينكدن", text_en: "LinkedIn"},
      {text_key: "youtube", text_ar: "يوتيوب", text_en: "Youtube"},
      {text_key: "guest_name", text_ar: "الضيف", text_en: "Guest Name"},
      {text_key: "host_name", text_ar: "المستضيف", text_en: "Host Name"},
      {text_key: "narrator_name", text_ar: "الراوي", text_en: "Narrator name"},
      {text_key: "cat_id", text_ar: "التصنيف", text_en: "Category"},
      {text_key: "item_url", text_ar: "الملف", text_en: "Item "},
      {text_key: "items", text_ar: "المديا", text_en: "Media items"},
      {text_key: "description", text_ar: "الوصف", text_en: "Description"},
      {text_key: "item_cat", text_ar: "أقسام المكتبة", text_en: "Items Cat"},
      {text_key: "branch_name", text_ar: "اسم الفرع", text_en: "Branch name"},
      {text_key: "branch_details", text_ar: "تفاصيل الفرع", text_en: "Branch details"},
      {text_key: "branches", text_ar: "مكاتينا", text_en: "Branches"},
      {text_key: "tyape", text_ar: "النوع", text_en: "Type"},
      {text_key: "part", text_en: "Part", text_ar: "الاجزءا"},
      {text_key: "pages", text_en: "Pages", text_ar: "الصحفات"},
      {text_key: "size", text_en: "Size", text_ar: "الحجم"},
      {text_key: "publisher", text_en: "Publisher", text_ar: "الناشر"},
      {text_key: "country", text_en: "Country", text_ar: "الدولة"},
      {text_key: "composser", text_en: "Composer", text_ar: "المؤلف"},
      {text_key: "university_name", text_ar: "الجامعة", text_en: "university name"},
      {text_key: "org_name", text_ar: "المؤسسة", text_en: "Organization name"},
      {text_key: "correction", text_ar: "التصحيح", text_en: "correction"},
      {text_key: "book", text_ar: "دليل مكتبة الادب الأسلامي", text_en: "Islamic library guide "},
      {text_key: "hijry", text_ar: "هجري", text_en: "هجري"},
      {text_key: "milady", text_ar: "ميلادي", text_en: "ميلادي"},
      {text_key: "prm_national", text_ar: "جنسية المؤلف", text_en: "prm national"},
      {text_key: "page_size", text_ar: "حجم الصحفة", text_en: "Page size"},
      {text_key: "notes", text_ar: "ملاحظات", text_en: "Notes"},
      {text_key: "b_date", text_ar: "تاريخ النشر", text_en: "Publish date"},
      {text_key: "b_date1", text_ar: "تاريخ التعديل", text_en: "Edit date"},
      {text_key: "cur_national", text_ar: "جنسية المصحح", text_en: "cur_national"},
      {text_key: "eqamma", text_ar: "الاقامة", text_en: "Eqamma"},
      {text_key: "mem_off", text_ar: "mem_off", text_en: "Mem Off"},
      {text_key: "deg", text_ar: "Deg", text_en: "Deg"},
      {text_key: "add_request", text_ar: "طلب اضافة كتاب", text_en: "Add Book Request"},
      {text_key: "groups", text_ar: "مجموعات المستخدمين", text_en: "Users Groups"},
      {text_key: "permission_rules", text_ar: "الصلاحيات", text_en: "permission rules"},
      {text_key: "module", text_ar: "القسم", text_en: "module"},
      {text_key: "method", text_ar: "الاجراء", text_en: "method"},
      {text_key: "group_id", text_ar: "مجموعة المستخدمين", text_en: "Users group"},
      {text_key: "ip_address", text_ar: "معرف IP", text_en: "IP address"},
      {text_key: "profiles", text_ar: "ملفات الأعضاء", text_en: "Profiles"},
      {text_key: "display_name", text_ar: "الاسم المعروض", text_en: "Display name"},
      {text_key: "first_name", text_ar: "الاسم الاول", text_en: "First Name"},
      {text_key: "last_name", text_ar: "الاسم الاخير", text_en: "Last name"},
      {text_key: "company", text_ar: "الشركة", text_en: "Company"},
      {text_key: "lang", text_ar: "اللغة", text_en: "Language"},
      {text_key: "bio", text_ar: "وصف مختصر", text_en: "Bio"},
      {text_key: "dob", text_ar: "  المراجعة", text_en: "Dob"},
      {text_key: "gender", text_ar: "الجنس", text_en: "Gender"},
      {text_key: "fax", text_ar: "فاكس", text_en: "Fax"},
      {text_key: "phone", text_ar: "هاتف", text_en: "Phone"},
      {text_key: "phone2", text_ar: "هاتف ٢", text_en: "Phone2"},
      {text_key: "postcode", text_ar: "الرمز البريدي", text_en: "postcode"},
      {text_key: "myphoto", text_ar: "الصورة", text_en: "Photo"},
      {text_key: "address_line1", text_ar: "العنوان ", text_en: "Address "},
      {text_key: "gravatar", text_ar: "الصورة الرمزية", text_en: "avatar"},
      {text_key: "birth_date", text_ar: "تاريخ الميلاد", text_en: "Birth Date"},
      {text_key: "birth_place", text_ar: "مكان الميلاد", text_en: "Birth Place"},
      {text_key: "nationality", text_ar: "الجنسية", text_en: "nationality"},
      {text_key: "new_request", text_ar: "طلبات الأنضمام", text_en: "Join Request"},
      {text_key: "contactSettings", text_ar: "معلومات الاتصال", text_en: "Contact Info"},
      {text_key: "address", text_ar: "العنوان", text_en: "Address"},
      {text_key: "webSettings", text_ar: "إعدادات الموقع", text_en: "Web Config"},
      {text_key: "meta_keyword", text_ar: "الكلمات المفتاحية", text_en: "Meta Keyword"},
      {text_key: "meta_description", text_ar: "الوصف", text_en: "Meta description"},
      {text_key: "js_code", text_ar: "كود Javascript", text_en: "Javascript Code"},
      {text_key: "css_code", text_ar: "كود CSS", text_en: "CSS Code"},
      {text_key: "footer_code", text_ar: "كود داخل الفوتر", text_en: "Footer Code"},
      {text_key: "headet_code", text_ar: "كود بالهيدر", text_en: "Header Code"},
      {text_key: "comments", text_ar: "التعليقات", text_en: "Comments"},
      {text_key: "comment", text_ar: "التعليق", text_en: "Comment"},
      {text_key: "is_active", text_ar: "المراجعة", text_en: "المراجعة"},
      {text_key: "logout", text_ar: "خروج", text_en: "logout"},
      {text_key: "created_on", text_ar: "تاريخ الاضافة", text_en: "Created on"},
      {text_key: "updated_on", text_ar: "تعديل في", text_en: "Updated on"},
      {text_key: "categories", text_ar: "التصنيفات", text_en: "Categories"},
      {text_key: "css", text_ar: "CSS", text_en: "CSS"},
      {text_key: "js", text_ar: "JS", text_en: "JS"},
      {text_key: "meta_title", text_ar: "عنوان ميتا", text_en: "Meta title"},
      {text_key: "meta_keywords", text_ar: "الكلمات المفتاحية", text_en: "Meta keywords"},
      {text_key: "rss_enabled", text_ar: "تفعيل Rss", text_en: "rss enabled"},
      {text_key: "comments_enabled", text_ar: "تفعيل التعليقات", text_en: "comments enabled"},
      {text_key: "soft_delete", text_ar: "الحذف", text_en: "soft delete"},
      {text_key: "asset_folder", text_ar: "مجلدات", text_en: "asset folder"},
      {text_key: "extension", text_ar: "الامتداد", text_en: "extension"},
      {text_key: "filename", text_ar: "الملف", text_en: "filename"},
      {text_key: "folder_id", text_ar: "المجلد", text_en: "folder"},
      {text_key: "asset", text_ar: "المرفقات", text_en: "Asset"},
      {text_key: "mail_list", text_ar: "النشرة البريدية", text_en: "Mail list"},
      {text_key: "magazine", text_ar: "المجلة", text_en: "magazine"},
      {text_key: "library", text_ar: "دليل مكتبة الادب الاسلامي", text_en: "library"},
      {text_key: "media", text_ar: "الميديا", text_en: "media"},
      {text_key: "view_results", text_ar: "عرض النتيجة", text_en: "View Results"},
      {text_key: "content", text_ar: "المحتوي", text_en: "Content"},
      {text_key: "link_url", text_ar: "الرابط", text_en: "link url"},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      ];
//
  }

}

