<!-- ========== Left Sidebar Start ========== -->


<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <li><a routerLink="./" aria-expanded="false">
        <i class="mdi mdi-24px mdi-monitor-dashboard"></i>
        <span > لوحة التحكم  </span>
      </a>

      </li>

      <li class="menu-title">المحتوي</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-newspaper"></i>
        <span > المقالات  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/news/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/news/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/news/deleted"> {{GetWordText('draft')}} </a></li>
         </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-tag"></i>
        <span > أقسام المقالات  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/categories/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/categories/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/categories/deleted"> {{GetWordText('draft')}} </a></li>
         </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-comma-box"></i>
        <span > التعليقات  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/comments/all">منشور  </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/comments/deleted"> بانتظار المراجعة</a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-page-layout-body"></i>
        <span >  الصفحات  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/pages/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/pages/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/pages/deleted"> {{GetWordText('draft')}} </a></li>
         </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-audio-video"></i>
        <span >  مكتبة الميديا  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/items/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/items/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/items/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-audio-video"></i>
        <span >  أقسام الميديا  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/item_cat/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/item_cat/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/item_cat/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>
 <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-dots-horizontal"></i>
        <span >  سلايدر  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/slider/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/slider/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/slider/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-face-profile"></i>
        <span > شخصيات الأدب الأسلامي  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/characters/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/characters/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/characters/deleted"> {{GetWordText('draft')}} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-handshake"></i>
        <span > شركاء النجاح  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/partnars/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/partnars/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/partnars/deleted"> {{GetWordText('draft')}} </a></li>
         </ul>
      </li>

      <li class="menu-title">المجلة</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-pdf-box"></i>
        <span >  المجلة الالكترونية  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/editions/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/editions/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/editions/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-pdf-box"></i>
        <span >  المجلة الفصيلة  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/editions-printed/upload"> مركز الرفع </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/editions-printed/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/editions-printed/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/editions-printed/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-pdf-box"></i>
        <span >  الاعداد PDF </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/pdf_editions/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/pdf_editions/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/pdf_editions/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>


      <li class="menu-title">المكتبة</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-book-alert"></i>
        <span >  المكتبة  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/book/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/book/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/book/deleted"> {{GetWordText('draft')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/book/addRequest">طلبات الأضافة </a></li>
        </ul>
      </li>





      <li class="menu-title">المرفقات</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-folder"></i>
        <span >  المجلدات   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/asset_folder/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/asset_folder/all"> منشور </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/asset_folder/deleted"> غير منشور </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-file"></i>
        <span >  المرفقات   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/asset/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/asset/all"> منشور </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/asset/deleted"> غير  منشور </a></li>
        </ul>
      </li>

      <li class="menu-title">الاعضاء</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-circle"></i>
        <span >  المستخدمين   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/users/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/users/all"> مفعل </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/users/deleted"> غير مفعل </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-check"></i>
        <span >  الاعضاء   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/profiles/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/profiles/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/profiles/deleted"> {{GetWordText('draft')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/profiles/newRequest"> طلبات العضوية </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-group"></i>
        <span >  المجموعات   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/groups/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/groups/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/groups/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li><li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-key"></i>
        <span >  الصلاحيات   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/permission_rules/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/permission_rules/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/permission_rules/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>
      <li class="menu-title">الرسائل</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-envelope"></i>
        <span >  الرسائل الواردة   </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/messages/all"> الرسائل السابقة </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/messages/deleted"> الرسائل الجديدة </a></li>
        </ul>
      </li>

 <li class="menu-title">النشرة البريدية</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-mailbox"></i>
        <span > المشتركين  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/mail_list/all"> المشتركين</a></li>
          <li><a  class="side-nav-link-ref" routerLink="/mail_list/export"> تصدير عناوين البريد </a></li>
        </ul>
      </li>


      <li class="menu-title">الأعدادات</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bx-map"></i>
        <span > مكاتبنا حول العالم  </span>
      </a>
        <ul  class="sub-menu" aria-expanded="false">
          <li><a  class="side-nav-link-ref" routerLink="/branches/add"> {{GetWordText('add')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/branches/all"> {{GetWordText('live')}} </a></li>
          <li><a  class="side-nav-link-ref" routerLink="/branches/deleted"> {{GetWordText('draft')}} </a></li>
        </ul>
      </li>

      <li><a  routerLink="/webSettings">
        <i class="bx mdi-24px bx-cog"></i>
        <span >  إعدادات الموقع   </span>
      </a>

      </li>




      <li><a  routerLink="/contactSettings">
        <i class="bx mdi-24px bxs-contact"></i>
        <span >  معلومات الاتصال   </span>
      </a>

      </li>


    </ul>


  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
