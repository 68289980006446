
export const environment = {

  production: true,
  serverUrl:"https://webapi.adabislami.org/",
  uploadUrl:"https://adabislami.org/upload/",
  mediaServerURL:"https://webapi.adabislami.org/application/",
 //serverUrl:"http://localhost:2500/",
 //mediaServerURL:"http://localhost:2500/upload_files/",
  app_auth_key_query:"?os=admin&api_key=b93e0a61edd5e0f7fe7515911e17820657c37d10439e78e8bc0c048e&api_code=b93e0a61edd5e0f7fe7515911e17820657c37d10439e78e8bc0c048e",
  defaultauth: 'adminApi',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

